function createElementFromHTML(html) {
  if (typeof html !== 'string') {
    return html;
  }

  const firstHTMLTag = html
    .replace(/^<!DOCTYPE[^>]*>\s*/, '')
    .replace(/^[<\s]*/g, '')
    .replace(/\r?\n|\r/g, '')
    .replace(/^([^>\s]+).*/g, '$1');
  let parentTag = 'div';

  if (firstHTMLTag === 'html') {
    parentTag = 'template';
  } else if (firstHTMLTag === 'tr') {
    parentTag = 'tbody';
  } else if (['thead', 'tbody', 'tfoot'].indexOf(firstHTMLTag) > -1) {
    parentTag = 'table';
  } else if (['th', 'td'].indexOf(firstHTMLTag) > -1) {
    parentTag = 'tr';
  }

  const elementParent = document.createElement(parentTag);
  elementParent.innerHTML = html;

  if ('content' in elementParent) {
    return elementParent.content;
  }

  return elementParent.children.length > 1 ? elementParent.children : elementParent.firstElementChild;
}

export default createElementFromHTML;
